import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/layouts/default-layout.js";
import Button from 'components/button';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Notice = makeShortcode("Notice");
const Action = makeShortcode("Action");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "what-is-puretones",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-puretones",
        "aria-label": "what is puretones permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is PureTones?`}</h2>
    <p>{`PureTones is an `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` for `}<a parentName="p" {...{
        "href": "/learn/"
      }}>{`learning`}</a>{`, `}<a parentName="p" {...{
        "href": "/tools/"
      }}>{`understanding`}</a>{` and making musical works in Indian Classical music.`}</p>
    <p>{`We have built PureTones based on an in-depth study and exploration of Indian Classical music to understand the role of harmonics, microtones and consonance in Indian Ragas and musical scales. It consists of a family of apps:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`PureTones Drone`}</strong>{` is a digital Tanpura (or Tambura) with controls for fine tuning the strings and adjusting their harmonic envelope. This enables skilled Indian Classical musicians to fine tune the harmonic envelope to suit whichever Raga they wish to perform or practice.`}</li>
      <li parentName="ul"><strong parentName="li">{`PureTones Musical Scale`}</strong>{` is a digital 12 note keyboard with controls for fine tuning each key to be consonant with an accompanying drone track. This enables skilled Indian Classical musicians to fine tune the musical scale to go with the PureTones Drone which they have finely tuned.`}</li>
      <li parentName="ul"><strong parentName="li">{`PureTones Sequencer`}</strong>{` is a sequencer which enables composition of melodies and musical works in Indian Classical music using notes from a fine tuned musical scale. It uses a simple but powerful grammar to accurately describe an Indian Classical music composition including support for microtones and note modulations. It synthesizes the audio from the description and plays it back.`}</li>
    </ul>
    <p>{`Contrary to widely held perceptions, a Tanpura in Indian Classical music is not only responsible for providing a reference of the fundamental note `}<strong parentName="p">{`Sa`}</strong>{`. It actually provides a tuning system resulting from string overtones which supports the entire Raga being performed. Skilled artists then employ appropriate shades of each note in the Raga they perform to render each note with utmost accuracy and clarity, in tune with the Tanpura. To understand this better, read `}<a parentName="p" {...{
        "href": "/learn/tanpuraworking-1/"
      }}>{`this article`}</a>{` which illustrates the role of the Tanpura in Indian Classical music.`}</p>
    <h2 {...{
      "id": "who-are-we",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#who-are-we",
        "aria-label": "who are we permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Who are we?`}</h2>
    <p>{`The PureTones team consists of:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`S Balachander`}</strong>{`, an Indian Classical musician, multi-instrumentalist, and inventor and performing artist of `}<a parentName="li" {...{
          "href": "https://www.chandraveena.com/",
          "target": "_blank",
          "rel": "nofollow noreferrer"
        }}>{`Chandraveena`}</a>{`, and`}</li>
      <li parentName="ul"><strong parentName="li">{`Aravind Iyer`}</strong>{`, a scientist and computer engineer, music technologist and a general music enthusiast.`}</li>
    </ul>
    <h2 {...{
      "id": "puretones-showcase",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#puretones-showcase",
        "aria-label": "puretones showcase permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PureTones Showcase`}</h2>
    <p>{`PureTones is used by the following artists to create their musical works.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.chandraveena.com",
          "target": "_blank",
          "rel": "nofollow noreferrer"
        }}>{`Chandraveena`}</a>{` - S Balachander on Chandraveena. Balachander makes extensive use of PureTones for his background drone tracks and also for exploring microtones and consonances in Indian Ragas and musical scales.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ragatronics.sadharani.com",
          "target": "_blank",
          "rel": "nofollow noreferrer"
        }}>{`Ragatronics`}</a>{` - A fusion of Indian Classical music with elements of rock, jazz and electronic music. Ragatronics features musical works composed by S Balachander and Aravind Iyer, but sequenced and recorded using PureTones.`}</li>
    </ul>
    <h2 {...{
      "id": "technology-behind-puretones",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#technology-behind-puretones",
        "aria-label": "technology behind puretones permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technology behind PureTones`}</h2>
    <p>{`PureTones has been developed using `}<a parentName="p" {...{
        "href": "https://faust.grame.fr/",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Faust`}</a>{`, a functional programming language for sound synthesis and audio processing.`}</p>
    <p>{`The Drone and Scale apps are built using the command line tool `}<inlineCode parentName="p">{`faust2webaudio`}</inlineCode>{` (see `}<a parentName="p" {...{
        "href": "https://faustdoc.grame.fr/manual/tools/",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Faust Tools`}</a>{` for details), while the Sequencer app has been built using `}<a parentName="p" {...{
        "href": "https://github.com/grame-cncm/faust2webaudio",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`faust2webaudio`}</a>{` which enables dynamic, in-browser compilation of Faust codes generated by the Sequencer app from the musical compositions. These apps use `}<a parentName="p" {...{
        "href": "https://webassembly.org/",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`WebAssembly`}</a>{` for efficient real-time audio synthesis.`}</p>
    <p>{`PureTones uses `}<a parentName="p" {...{
        "href": "https://reactjs.org",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`React`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Gatsby`}</a>{` and `}<a parentName="p" {...{
        "href": "https://mdxjs.com",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`MDX`}</a>{` to deploy the PureTones apps, articles and audio demonstrations, in the form of a website and web application.`}</p>
    <h2 {...{
      "id": "support-puretones",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#support-puretones",
        "aria-label": "support puretones permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Support PureTones`}</h2>
    <p>{`PureTones is under development. Please help us improve PureTones to serve the music community. If you are a `}<strong parentName="p">{`music student`}</strong>{` or a `}<strong parentName="p">{`musician`}</strong>{`, please try out our apps from the `}<a parentName="p" {...{
        "href": "/app/"
      }}>{`App`}</a>{` page and let us know your feedback or requests.`}</p>
    <p>{`If you are a music patron, please listen to recordings by our our featured artists:  `}<a parentName="p" {...{
        "href": "https://chandraveena.bandcamp.com/",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Chandraveena`}</a>{` and `}<a parentName="p" {...{
        "href": "https://ragatronics.bandcamp.com",
        "target": "_blank",
        "rel": "nofollow noreferrer"
      }}>{`Ragatronics`}</a>{`. If you like these recordings, please consider supporting our development efforts by their purchasing musical works.`}</p>
    <Notice mdxType="Notice">
      <center>You can reach us at <strong>bala.chander@sadharani.com</strong> or <strong>aravind.iyer@sadharani.com</strong>.
        <br />
        <Action mdxType="Action"><a target="_blank" rel="nofollow noreferrer" href="mailto:bala.chander@sadharani.com?cc=aravind.iyer@sadharani.com">Get in Touch</a></Action>
      </center>
    </Notice>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      